h1 {
  font-family: "nimbus-sans-condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 60pt;
  letter-spacing: -3.5pt;
  width: 30%;
  text-align: center;
  line-height: 45pt;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: background-color 5000s ease-in-out 0s;
    border-radius: 0;
}

@media (max-width: 600px) {
  h1 {
    font-size: 50pt;
    letter-spacing: -1.75pt;
    width: 100%;
    line-height: 39pt;
  }

  h3 {
    font-size: 10pt;
  }
}

h2 {
  font-family: "nimbus-sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  margin: 0;
  font-size: 12pt;
  letter-spacing: -0.3pt;
  line-height: 10pt;
}


h4 {
  font-family: "nimbus-sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.3px;
  font-size: 11pt;
  color: #000000;
}


h5 {
  font-family: "nimbus-sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.3px;
  font-size: 8pt;
  line-height: 7pt;
  text-align: center;
  margin: 0;
}


h6 {
  font-family: "nimbus-sans", sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 150pt;
  margin:0;
  line-height: 115pt;
  letter-spacing: -20pt;
}

h3 {
  font-family: "nimbus-sans-condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 11pt;
  letter-spacing: 3%;
  color: #FFF;
}

@keyframes blink {
  0% {
    background-color: red;
  }

  50% {
    background-color: black;
  }

  100% {
    background-color: red;
  }
}

.blink {
  animation: blink 1.5s infinite ease-in-out;
}

.invert-effect {
  filter: invert(1);
  -webkit-filter: invert(1);
}


@keyframes fadeInOut {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

iframe {
  will-change: transform;
}

.smooth-opacity {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.smooth-opacity:hover {
  opacity: 0.7;
}