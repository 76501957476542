:root {
  --black: #000000;
  --white: #FFFFFF;
  --dark: #1A1A1A;
  --yellow: #FCC303;
  --grey: #8C8C8C;
  --lightGrey: #D8D8D8;
}

@media screen and (max-width:767px) {
  html, body {
    overflow: hidden;
  }
  }
  
body,
html {
  height: 100%;
  margin: 0;
  overflow: hidden;
  font-family: Helvetica, Arial, sans-serif;
}

a {
  text-decoration: none;
}
